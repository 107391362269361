import { template as template_ec44613a00844c278c69d8d9d424169f } from "@ember/template-compiler";
const FKLabel = template_ec44613a00844c278c69d8d9d424169f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
