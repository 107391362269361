import { template as template_424ccdabab83482289ff01862c30460f } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_424ccdabab83482289ff01862c30460f(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
