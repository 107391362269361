import { template as template_ac430d2f83c64aafaac148a6a4ef2988 } from "@ember/template-compiler";
const WelcomeHeader = template_ac430d2f83c64aafaac148a6a4ef2988(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
